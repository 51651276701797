import { ml } from "./../core/common/matrixlib";
import { InitializeBusinessLogic, ItemConfiguration, RestConnector } from "../core/common/businesslogic/index";
import { Application } from "../core/common/UI/Application";
import { NavigationBar, KeyboardShortcuts } from "../core/common/UI/Components/index";
import { MatrixReq } from "./../core/common/businesslogic/index";
import { MatrixSession } from "./../core/common/businesslogic/index";
import { RestDB } from "./../core/common/businesslogic/index";
import { JsonValidator } from "./../jsonvalidation/JsonValidator";
import { ServerStorageAdmin } from "./../core/admin/ServerStorageAdmin";
import { ProjectStorageAdmin } from "./../core/admin/ProjectStorageAdmin";
import { ServerStorage } from "./../core/client/ServerStorage";
import { ServerStorageMobile } from "./../core/mobile/ServerStorageMobile";
import {
    app,
    applyResponsiveView,
    globalMatrix,
    InstallLegacyAdaptor,
    matrixSession,
    restConnection,
    setApp,
    setIC,
    setMatrixApplicationUI,
    setMatrixSession,
    setRestConnection,
} from "../core/globals";
import { plugins } from "../core/common/businesslogic/index";
import { InitializeUI } from "./../core/common/UI/initialize";
import { InitializePlugins } from "./../core/client/plugins/initialize";

// these are all activities done once the client and the JS libraries have been loaded
ml.Logger.log("info", "Starting Application");
// avoid redirect if image is dropped in bad area
window.addEventListener(
    "dragover",
    function (e) {
        e = e || <DragEvent>event;
        if (e.preventDefault) e.preventDefault();
    },
    false,
);
window.addEventListener(
    "drop",
    function (e) {
        e = e || <DragEvent>event;
        if (e.preventDefault) e.preventDefault();
    },
    false,
);

globalMatrix.matrixProduct = $("meta[name='mx-product']").attr("content");
globalMatrix.matrixRestUrl = $("meta[name='mx-rest-url']").attr("content");
globalMatrix.matrixBaseUrl = $("meta[name='mx-base-url']").attr("content");
globalMatrix.matrixUniqueSerial = $("meta[name='mx-unique-serial']").attr("content");
globalMatrix.matrixWfgw = $("meta[name='mx-pluginrest-url']").attr("content");
globalMatrix.matrixExpress = $("meta[name='mx-express']").attr("content") == "true";
globalMatrix.mxOauth = $("meta[name='mx-oauth']").attr("content");
globalMatrix.mxOauthLoginUrl = $("meta[name='mx-oauth-login-url']").attr("content");
let mxBannerMessage = $("meta[name='mx-banner-message']").attr("content");

if (mxBannerMessage) {
    //let  doc = new DOMParser().parseFromString(mxBannerMessage, "text/html");
    //let unesc = doc.documentElement.textContent;

    let msg = $("<div class='bannerMessage'>").html(mxBannerMessage);
    $("body").append(
        $("<div id='banner' class='message_container'>").append(
            msg.append(
                $("<div class='message_close'>X</div>").click(function () {
                    $("#banner").remove();
                }),
            ),
        ),
    );
}

// disable bootstrap button function - otherwise jquery dialog does not show x to close
try {
    (<{ button: { noConflict: Function } }>$.fn).button.noConflict();
} catch (err) {
    console.error("Cannot disable bootstrap button function - So jquery dialog will not show x to close");
}

setMatrixApplicationUI(new Application());

// init main components
setRestConnection(new RestConnector({ server: globalMatrix.matrixRestUrl }));
globalMatrix.wfgwConnection = new RestConnector({ server: globalMatrix.matrixWfgw });
var db = new RestDB(restConnection);

// TODO(modules): per Yves, matrixProduct isn't really used anymore, we should be able to remove it.
if (globalMatrix.matrixProduct === "Admin") {
    globalMatrix.serverStorage = new ServerStorageAdmin();
    globalMatrix.projectStorage = new ProjectStorageAdmin();

    // TODO(module): there isn't really a type Admin...well, there is an empty interface in globals.ts.
    // For the admin client, a different init.ts file is used so I don't think this code is ever called.
    // For now, set it to an empty object.
    // setApp(new Admin(db, false));
    setApp({});
} else if (globalMatrix.matrixProduct === "Launch") {
    globalMatrix.serverStorage = new ServerStorageAdmin();
    globalMatrix.projectStorage = new ProjectStorageAdmin();

    setApp(new MatrixReq(db));
} else if (typeof globalMatrix.mobileApp !== "undefined") {
    globalMatrix.serverStorage = new ServerStorageMobile();
    setApp(new MatrixReq(db));
} else {
    globalMatrix.serverStorage = new ServerStorage(globalMatrix.matrixBaseUrl, DOMPurify);
    setApp(new MatrixReq(db));
}

if (app.getVersion() !== undefined) {
    const simplifiedVersion = app
        .getVersion()
        .split(".")
        .filter((value, index) => index < 3)
        .join(".");
    $("#version").text(simplifiedVersion + app.getVersionQualifier());
}

if ($("#sidebar").length) {
    // restore main ui layout
    var sidebar = localStorage.getItem("sidebar");
    if (!sidebar) {
        sidebar = "200px";
    }
    try {
        if (Number(sidebar.replace("px", "")) < 50) {
            sidebar = "200px";
        }
    } catch (ex) {}

    let totalWidth = Number(sidebar.replace("px", ""));

    totalWidth = Math.min(totalWidth, $(document).width() * 0.4); // use at most 40% for tree
    $("#sidebar").width(totalWidth);
}

$("#dragbar").mousedown(function (e) {
    if (e.preventDefault) e.preventDefault();

    $(document).mousemove(function (e) {
        if (e.pageX - NavigationBar.navbarWidth > 200) {
            localStorage.setItem("sidebar", e.pageX + 2 - NavigationBar.navbarWidth + "px");
            $("#sidebar").width(e.pageX + 2 - NavigationBar.navbarWidth);
            $("#savedSearches").css("max-width", e.pageX + 2 - NavigationBar.navbarWidth - 10);
            $("#savedSearches li a").css("max-width", e.pageX + 2 - NavigationBar.navbarWidth - 10);
        }
    });
});

$("#dragbar").mouseup(function (e) {
    app.resizeItem(true);
});

window.onresize = () => {
    app.resizeItem(true);
};

if ($("#nav-bar-full-responsive").is(":visible")) {
    // auto switch to responsive
    $("#nav-bar-full-responsive").click(function () {
        let mobileView = localStorage.getItem("mobileLayout");
        if (globalMatrix.globalShiftDown) {
            localStorage.setItem("mobileLayout", "");
        } else if (mobileView == "0" || mobileView == "") {
            localStorage.setItem("mobileLayout", "1");
        } else if (mobileView == "1") {
            localStorage.setItem("mobileLayout", "2");
        } else {
            localStorage.setItem("mobileLayout", "0");
        }
        applyResponsiveView();
    });
    window.setTimeout(function () {
        applyResponsiveView();
    }, 400);
} else {
    localStorage.setItem("mobileLayout", "");
}

$(document).mouseup(function (e) {
    $(document).off("mousemove");
});

// hook up main ui interface

var kb = new KeyboardShortcuts();
kb.init();

$(window).on("beforeunload", function (e): string | void {
    if (app.getNeedsSave()) {
        return "You have unsaved changes!";
    }
});
window.addEventListener(
    "popstate",
    function (event) {
        matrixSession.browserNavigation();
    },
    false,
);

// handles login etc
setMatrixSession(new MatrixSession());
globalMatrix.jsonValidator = new JsonValidator(globalMatrix.matrixBaseUrl, app.getVersion());

InitializeBusinessLogic();
setIC(new ItemConfiguration(ml.Logger, ml.JSON));
plugins.setUI($("#appPopup"));
InitializeUI();
InitializePlugins();
InstallLegacyAdaptor();
ml.Logger.log("info", "Application started");

document.addEventListener("touchstart", handleTouchStart, false);
document.addEventListener("touchmove", handleTouchMove, false);

let xDown: number | null = null;
let yDown: number | null = null;

function getTouches(evt: TouchEvent) {
    // @ts-expect-error don't want to bother with typing JQuery event, because it's way too generic and requires to write a proper type guard
    return evt.touches || evt.originalEvent.touches; // evt.touches - browser API, evt.originalEvent.touches - jQuery
}

function handleTouchStart(evt: TouchEvent) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
}

function handleTouchMove(evt: TouchEvent) {
    if (!xDown || !yDown) {
        return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if (Math.abs(xDiff) > window.outerWidth / 4) {
        /* real swipe*/
        if (xDiff > 0) {
            localStorage.setItem("mobileLayout", "0");
        } else {
            localStorage.setItem("mobileLayout", "1");
        }
        xDown = null;
        yDown = null;
        applyResponsiveView();
    }
    /* reset values */
}

// replace default console log
// (function(){
//     var oldLog = console.log;
//     console.log = function (message) {
//         var d = new Date();
//         var m = "" + (d.getMilliseconds() + 10000);
//
//         oldLog.apply(console, arguments);
//     };
// })();

// install default error handler for uncaught errors
window.onerror = function (msg, url, line) {
    var message = "Caught[via window.onerror]: '" + msg + "' from " + url + ":" + line;
    var d = new Date();
    var m = "" + (d.getMilliseconds() + 10000);

    ml.Logger.log("error", message);
    return false; // keep logging
};

/**
// debugging functionality for old browsers
(function () {
    var method:string;
    var noop = function () {
    };
    var methods:string[] = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());
*/
